<template>

  <div class="relative w-full max-w-6xl my-3 mx-auto">
    <div class="flex justify-between items-center">
      <h1 class="text-3xl py-10">{{exam.name}}</h1>
    </div>
    <div class="w-full my-3">

      <div class="my-3 relative" style="min-height: 400px">

        <overly class="rounded" background="bg-blue-100" v-if="loading">
          <spinner class="text-red-600 h-10 w-10"></spinner>
          Loading...
        </overly>

        <div class="mb-4">

          <div class="py-2 w-full text-center mb-10">

            <div class="text-3xl">
              <span @click="loadResultData">Exam Result</span>
            </div>

            <div class="value font-semibold  text-xl">{{ resultData.examName }}</div>
          </div>

          <div class="item-each border-t">
            <span class="key">Full Mark: </span>
            <span class="value">{{ resultData.numOfQuestions * resultData.perQuestionMark }}</span>
          </div>

          <div class="item-each">
            <span class="key">Correct Mark: </span>
            <span class="value" v-if="resultData.hasParticipate">{{ resultData.correct_answers_mark}}</span>
            <span class="value" v-else>-</span>
          </div>

          <div class="item-each" :class="resultData.negativeMark > 0 ? 'bg-red-100 text-red-900':''">
            <span class="key">Negative Mark: </span>
            <span class="value" v-if="resultData.hasParticipate">{{ resultData.negativeMark}}</span>
            <span class="value" v-else>-</span>
          </div>

          <div class="item-each bg-blue-200 font-bold text-blue-900 text-xl">
            <span class="key">Obtained Mark: </span>
            <span>
              <span class="value"  v-if="resultData.hasParticipate">{{ mark.obtained }}</span>
              <span class="value" v-else>-</span>
            </span>

            <span
                class="text-red-600 font-normal text-base ml-5"
                v-if="resultData.candidateType == 2 && mark.deduction > 0">{{ mark.deduction}} Mark reduced for second timer</span>

          </div>

          <div class="item-each">
            <span class="key">Position: </span>
            <span class="value">
              <position v-if="resultData.hasParticipate" :value="resultData.position"></position>
              <span v-else>-</span>
            </span>
          </div>

          <div class="item-each">
            <span class="key">Highest Mark: </span>
            <span class="value">
              {{ resultData.highestMark }}
            </span>
          </div>

          <div class="item-each">
            <span class="key">Total Participants: </span>
            <span class="value">{{ resultData.totalExaminee}}</span>
          </div>

        </div>

        <div class="flex justify-center">

          <btn :router-path="'/my/exams/' + examId + '/result/' + participantId + (answerListLoaded ? '':'/answers')"
               :loading="answerListLoading"
               @click="answerListLoading = !answerListLoaded ? true : answerListLoading; answerListLoaded = false"
          >{{ answerListLoaded ? 'Hide Answers':'View Answers' }}</btn>


        </div>

        <div>
          <router-view :examId="examId"
                       :participantId="participantId"
                       @failed="answerListLoading= !answerListLoaded ? true : answerListLoading; answerListLoaded = false"
                       @loaded="answerListLoading=false; answerListLoaded=true"></router-view>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import Spinner from "../../../components/spinner";
import Overly from "../../../components/overly";
import Position from "./components/position";
import Btn from "../../../components/btn";
export default {
  name: "exam-participation",
  components: {Btn, Position, Overly, Spinner},
  data( ){
    return {
      resultData: {},
      exam: {},
      questions: [],
      submittingQuestions:[],
      answerStatusList: {},
      loading:true,
      answerListLoading: false,
      answerListLoaded: false,
      endTime: '',
      exam_time: '00:00:00'
    }
  },
  methods:{
    finishExam(){

    },
    questionOptions( question ){
      return Array.isArray( question.questionOptions ) ? question.questionOptions: [];
    },
    answerSubmit(e) {
      this.submittingQuestions.push(parseInt(e.target.name));

      const data = {
        participant_exam_id   : this.participantExamId,
        question_id           : e.target.name,
        answer                : e.target.value,
      }

      const action = (input, event ) => {
        this.$store.dispatch( 'exam/answer', {
          data: input,

          success: (res) => {
            const data = res.data;
            this.hideOverly(input.question_id);
            this.answerStatusList[input.question_id] = {text: "Submitted", statusCode: res.status };
          },
          error: (res) => {
            event.target.checked = false;
            this.hideOverly(input.question_id);
            this.answerStatusList[input.question_id] = {text: "Failed to submit, try again.", statusCode: typeof res == 'undefined' ? 0: res.status };
          }
        });
      }

      setTimeout(()=> {
        action(data,e);
      }, 10);

    },
    hideOverly(questionId) {
      const index = this.submittingQuestions.indexOf(parseInt(questionId));
      if (index > -1) {
        this.submittingQuestions.splice(index, 1);
      }
    },
    responseMessage(questionId) {
      const messageData = this.answerStatusList[questionId];

      if(messageData) {
        let className = '';

        if( messageData.statusCode >= 200 && messageData.statusCode <=206 ) {
          className = 'text-green-400';
        } else {
          className = 'text-red-400';
        }

        return {
          text: messageData.text,
          class: className,
        };
      }
      return {
        text: "",
        class: "",
      };
    },

    loadResultData(){

      const data = {
        participant_id: this.participantId,
        exam_id: this.examId
      };

      this.$store.dispatch( 'exam/result', {
        params: data,
        success: ({data}) => {
          this.resultData = data;
          this.exam = data.exam || { };
          this.questions = data.examQuestions || [ ];
          this.endTime = data.endTime;
          this.loading = false;
        },
        error: () => {
          //this.$router.push( { path: '/my/courses' });
        }
      });


    }

  },
  computed: {
    participantId () {
      return this.$route.params.participantId;
    },
    examId () {
      return this.$route.params.examId;
    },
    mark(){

      let deduction = this.resultData.secondTimerDeduction || 0;
      let obtained = this.resultData.obtainedMark - ( ( this.resultData.candidateType == 2 ) ? deduction:0 )

      return {obtained, deduction}
    }

  },

  created() {
    this.loadResultData();
  }

}
</script>

<style scoped>
  .item-each {
    @apply border-b  py-2 w-full
  }

  .item-each .key {
    @apply w-3/12 inline-block
  }

</style>